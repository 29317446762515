.rc-swipeout-btn-text {
  width: 24px;
  height: 24px;
  margin: 0 24px;
  background-image: url('trash.svg');
  background-size: cover;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}
