@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-base text-zinc-600 antialiased;
    /* text-base's line-height is 1.25rem. Notice the explicit rem units.
     * Our old global styles had a line-height of 1.25 (without units) applied to the body.
     * The difference is that a unit-less value is equivalent to an em value, which varies
     * depending on the font-size of the element. Many components were relying on this to,
     * for example, make larger text have a larger line-height without setting it explicitly.
     *
     * Using pure tailwind this is not a problem, since applying text-{size} sets both the size
     * and an explicit line-height, but because of the old components it would be risky to remove
     * the unitless rule.
     */
    line-height: 1.25;
    /* temporary workaround while the fix for this chromium bug reaches the stable channel:
     * https://bugs.chromium.org/p/chromium/issues/detail?id=1521160
     */
    font-synthesis: none;
  }

  input,
  textarea,
  input:focus,
  textarea:focus {
    outline: none !important;
  }

  a:focus-visible,
  button:focus-visible {
    @apply outline outline-2 outline-tan-blue-500;
  }

  html {
    @apply scroll-smooth;
  }

  input[autocomplete='off']::-webkit-credentials-auto-fill-button,
  input[autocomplete='off']::-webkit-caps-lock-indicator {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
  }
}

@layer components {
  a {
    @apply no-underline text-tan-blue-500 hover:text-tan-blue-500 focus:text-tan-blue-500;
  }
}
