/* Notification Dropdown */
.notifications-section {
  .notification-title {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-gallery);
    padding: 9px 15px;
    color: var(--color-mine-shaft);
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
  }
  .close-trigger {
    color: var(--color-gray);
    font-size: 10px;
    &:hover {
      text-decoration: none;
    }
  }
  .items-holder {
    overflow: auto;
    max-height: 200px;
  }
  .item {
    padding: 15px;
    background: var(--color-white);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray);
    border-top: 1px solid var(--color-gallery);
    &:hover {
      .notification-close-trigger {
        opacity: 1;
      }
    }
  }
  .item-inner {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: flex-start;
    padding: 15px;
    border-top: 1px solid var(--color-gallery);
    &:hover .notification-close-holder {
      opacity: 1;
    }
  }
  .userpic-holder {
    padding-right: 15px;
    a {
      display: flex;
    }
    img {
      border-radius: 50%;
    }
  }
  .content-holder {
    flex-grow: 1;
  }
  .action-holder {
    padding-left: 20px;
  }
  .notification-close-holder {
    padding-left: 20px;
    font-size: 10px;
    width: 35px;
    text-align: right;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: 150ms;
  }
  .notification-close-trigger {
    opacity: 0.7;
    color: var(--color-gray);
    align-self: flex-start;
    &:hover {
      text-decoration: none;
    }
  }
  .user-name {
    color: var(--color-mine-shaft);
  }
  .time {
    font-size: 13px;
    color: var(--color-silver);
  }
}
@include media-breakpoint-down(lg) {
  .notifications-section {
    .notification-close-trigger {
      opacity: 1;
    }
  }
}
@include media-breakpoint-down(sm) {
  .notifications-section {
    width: 100%;
    .items-holder {
      max-height: 150px;
    }
  }
}
@include media-breakpoint-down(xs) {
  .notifications-section {
    .notification-close-holder {
      display: none;
    }
    .notification-title {
      font-size: 13px;
      line-height: 18px;
      padding: 5px 10px;
    }
    .items-holder {
      max-height: 120px;
    }
    .item {
      padding: 10px;
      font-size: 12px;
      line-height: 18px;
    }
    .time {
      font-size: 11px;
    }
    .action-holder {
      padding-left: 0;
      margin-top: 0;
    }
  }
}

body.body-overflow-hidden {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  position: fixed;
}
